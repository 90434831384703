<template>
  <div>
    <Navbar />
    <section
      class="bg-half-260 d-table w-100"
      style="background: url('images/digital/home-bg.png') center center"
      id="home"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <div class="title-heading mt-4">
              <h1 class="heading mb-3">
                <span class="text-primary">嗨嗨</span> 數位<br />
                <span class="text-primary">HiHi</span> Digital
              </h1>
              <p class="para-desc text-muted">
                資訊解決方案的最佳夥伴<br>
                透過我們的專業，讓客戶的專業更具價值及競爭力
              </p>
              
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <img src="/images/digital/seo.gif" class="img-fluid" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <section class="section overflow-hidden">
      <div class="container">
        <div class="row text-center pb-5">
          <div class="col-12">
            <h4 class="title mb-0">我們提供什麼服務？</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <div class="card features px-md-3 border-0 text-center">
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-flip-v"></i>
              </div>
              <div class="card-body p-0 content">
                <h5 class="mt-4">
                  <a href="javascript:void(0)" class="title text-dark">
                    各式網站系統建置
                  </a>
                </h5>
                <p class="text-muted">
                  我們擅長各種網站的開發及建置，從兼具美感及視覺設計的「形象網站」，到各種類型的「線上系統」，領域橫跨電商、醫療、直播、教育...等等，嗨嗨數位都能協助您。
                </p>
                <a href="javascript:void(0)" class="text-primary">了解更多
                  <chevron-right-icon class="fea icon-sm"></chevron-right-icon>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-4 pt-sm-0">
            <div class="card features px-md-3 border-0 text-center">
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-layer-group"></i>
              </div>
              <div class="card-body p-0 content">
                <h5 class="mt-4">
                  <a href="javascript:void(0)" class="title text-dark">
                    網站託管/維運
                  </a>
                </h5>
                <p class="text-muted">
                  維持服務的穩定和可用性，以最高規則安全考量，是嗨嗨數位的一大強項，我們擁有多年雲服務的經驗，擅長各類雲端伺服器的維運、監控。
                </p>

                <a href="javascript:void(0)" class="text-primary">了解更多
                  <chevron-right-icon class="fea icon-sm"></chevron-right-icon>
                </a>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-4 pt-lg-0">
            <div class="card features px-md-3 border-0 text-center">
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-object-group"></i>
              </div>
              <div class="card-body p-0 content">
                <h5 class="mt-4">
                  <a href="javascript:void(0)" class="title text-dark">
                    雲端資訊顧問
                  </a>
                </h5>
                <p class="text-muted">
                  我們提供各式顧問諮詢的服務，從資安到維運 (SRE, DevOps...) 或是 SEO 的相關專業，甚至是公司的開發流程到上雲規劃，數位轉型過程中，嗨嗨數位都在您的身旁。
                </p>
                <a href="javascript:void(0)" class="text-primary">了解更多
                  <chevron-right-icon class="fea icon-sm"></chevron-right-icon>
                </a>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-md-6">
            <img src="/images/digital/about.png" class="img-fluid" alt="" />
          </div>
          <!--end col-->

          <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title">
              <h4 class="title mb-4">設計與體驗</h4>
              <p class="text-muted">
                透過多年對互動介面的研究，我們在意每個使用者的易用性及體驗，並透過兼具美觀及易用性的介面設計，讓您的系統或網站，都能在電腦及行動裝置上獲得完美的使用體驗。
              </p>
              <p class="text-muted">
                用戶的體驗是我們所在意的。透過高品質的介面和視覺呈現，不只希望解決你的商業難題，更希望能讓你愛上這個系統。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-12 order-2 order-md-1">
            <div class="section-title">
              <h4 class="title mb-4">
                技術及專業
              </h4>
              <p class="text-muted">
                我們提供業界最新、最專業的技術，來協助客戶進行數位轉型，無論從高穩定高可用性的系統設計，到各種資訊安全的防範和堅持，我們希望透過技術賦予客戶服務更高的商業價值。
              </p>
              <p class="text-muted">
                嗨嗨數位的技術人員，隨時保持業界最新資訊的更新，並且在協助用戶數位化優化的過程，我們也不斷在優化我們的流程和技術，以確保我們供應的服務品質。
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 order-1 order-md-2 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <img src="/images/illustrator/app.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-12 order-1 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <img src="/images/illustrator/Startup_SVG.svg" alt="" style="max-width: 420px;"/>
          </div>
          <div class="col-lg-6 col-md-6 col-12 order-2 order-md-2">
            <div class="section-title">
              <h4 class="title mb-4">
                穩定且具彈性
              </h4>
              <p class="text-muted">
                建構穩定且高可用性的系統，已經是我們持續且一貫的作法，透過分散式架構、完整的監控警示系統、自動化持續交付、自動化測試，以及具有彈性的架構設計等，我們協助客戶在數位化轉型的過程中享受更穩定的服務。
              </p>
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>
                  叢集式架構設計，分散流量並提高負載能力
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                  </span>
                  高要求的程式品質，搭配自動化單元測試及部署
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                  </span>
                  持續的服務監控，高規格資安防護並隨時紀錄 Log
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2">
                    <i class="uil uil-check-circle align-middle"></i>
                  </span>
                  每日異地備援，將意外發生時的影響降至最低
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>

<script>
import Navbar from '@/components/navbar';
import Features from '@/components/features';
import Footer from '@/components/footer';
import {
  ChevronRightIcon,
  ArrowUpIcon,
} from 'vue-feather-icons';

export default {
  components: {
    Navbar,
    Features,
    Footer,
    ChevronRightIcon,
    ArrowUpIcon,
  },
  data() {
    return {
      featuresData: [
        {
          icon: 'uil uil-flip-h',
          title: 'Built for Everyone',
          description:
            'Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper.',
        },
        {
          icon: 'uil uil-minus-path',
          title: 'Responsive Design',
          description:
            'Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.',
        },
        {
          icon: 'uil uil-layers-alt',
          title: 'Build Everything',
          description:
            'It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.',
        },
      ],
    };
  },
};
</script>
